import dayjs from 'dayjs'
type TimeFormat = 'YYYY.MM.DD' | 'MM.DD' | 'M.D' | 'M.DD'

/**
   * 金额加单位
   * @param num
   * @returns
   */
export function unitToString (num: number): string {
  if (!num) return '0.00'
  if (Number(num) >= 10000) {
    return formatNum(parseInt(num / 1000 + '') + '') + 'K'
  } else {
    return formatNum(num + '')
  }
}

/**
   * 数字加逗号
   * @param str
   * @returns
   */
export function formatNum (str: string) {
  if (!str) return ''
  let newStr = ''
  let count = 0
  str = str + ''
  // 当数字是整数
  if (str.indexOf('.') === -1) {
    for (let i = str.length - 1; i >= 0; i--) {
      if (count % 3 === 0 && count !== 0) {
        newStr = str.charAt(i) + ',' + newStr
      } else {
        newStr = str.charAt(i) + newStr
      }
      count++
    }
    str = newStr // 自动补小数点后两位
    return str
  } else { // 当数字带有小数
    for (let i = str.indexOf('.') - 1; i >= 0; i--) {
      if (count % 3 === 0 && count !== 0) {
        newStr = str.charAt(i) + ',' + newStr
      } else {
        newStr = str.charAt(i) + newStr // 逐个字符相接起来
      }
      count++
    }
    str = newStr + (str + '00').substr((str + '00').indexOf('.'), 3)
    return str
  }
}

/**
 * 开始时间与结束时间 结合转换器 (如:2021.10.10 - 2022.12.01)
 * 同年/同月/同日 只显示一个 （如:2021.01.01 - 02.01 , 01.01 - 03, 01.01）
 * @param startTimestamp 开始时间戳
 * @param endTimestamp 结束时间戳
 * @param format 输出格式
 * @returns
 */
export const getStartDateToEndDate = (startTimestamp: number, endTimestamp: number, format: TimeFormat) => {
  if (!startTimestamp || !endTimestamp) return ''

  let month_format = 'MM'
  let day_format = 'DD'
  if (format !== 'YYYY.MM.DD') {
    const arr = format.split('.')
    month_format = arr[0]
    day_format = arr[1]
  }
  const [start, end] = [dayjs(startTimestamp), dayjs(endTimestamp)]
  const [start_year, end_year] = [start.format('YYYY'), end.format('YYYY')]
  const [start_month, end_month] = [start.format(month_format), end.format(month_format)]
  const [start_day, end_day] = [start.format(day_format), end.format(day_format)]
  const start_result: string[] = [start_year, start_month, start_day]
  const end_result: string[] = []

  // 同年
  const isSameYear = start_year === end_year
  // 同年同月
  const isSameYearAndMonth = isSameYear && start_month === end_month

  // 同年同月同日
  const isSameDay = isSameYearAndMonth && start_day === end_day

  if (isSameDay) {
    // 同年同月同日
    end_result.push(...[])
  } else if (isSameYearAndMonth) {
    // 同年同月
    end_result.push(end_day)
  } else if (isSameYear) {
    // 同年
    end_result.push(...[end_month, end_day])
  } else {
    // 全不同
    end_result.push(...[end_year, end_month, end_day])
  }

  if (format === 'YYYY.MM.DD') {
    if (end_result.length === 0) {
      return `${start_result.join('.')}`
    } else {
      return `${start_result.join('.')} - ${end_result.join('.')}`
    }
  } else {
    start_result.shift()
    end_result.length === 3 && end_result.shift()
    if (end_result.length === 0) {
      return `${start_result.join('.')}`
    } else {
      return `${start_result.join('.')} - ${end_result.join('.')}`
    }
  }
}

// base64格式转文件格式
export function dataURLtoFile (dataurl: string, filename: string) {
  const arr = dataurl.split(',')
  if (!arr) return
  const mime = (arr[0].match(/:(.*?);/) || [])[1]
  const bstr = window.atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  const file_name = filename.split('.')[0] + '.' + mime.split('/')[1]
  return new File([u8arr], file_name, { type: mime })
}

// 图片路径转base64
export function getBase64Image (img:any) {
  const canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height
  const ctx:any = canvas.getContext('2d')
  ctx.drawImage(img, 0, 0, img.width, img.height)
  const dataURL = canvas.toDataURL('image/png')
  return dataURL
}
