import { logoutApi } from '@/assets/scripts/apis/home-api'
import { GOODS_ADD, GOODS_EDIT } from '@/assets/scripts/utils/constant'
import { STATUS_CODE } from '@/assets/scripts/utils/enum'
import { goToLogin } from '@/assets/scripts/utils/link'

export default {
  namespaced: true,
  state: {
    sideMenuActiveName: 'Home',
    hasChange: false // 是否正在修改
  },
  mutations: {
    setSideMenuActiveName (state: { sideMenuActiveName: string }, name: string) {
      state.sideMenuActiveName = name
    },
    setHasChange (state: { hasChange: boolean }, data: boolean) {
      state.hasChange = data
    }
  },
  actions: {
    // 退出登陆
    logout () {
      logoutApi().then((res: any) => {
        if (res.code === STATUS_CODE.SUCCESS) {
          sessionStorage.setItem(GOODS_ADD, '')
          sessionStorage.setItem(GOODS_EDIT, '')
          goToLogin()
        }
      })
    }
  },
  modules: {
  }
}
