// 请求返回状态码
export enum STATUS_CODE {
  SUCCESS = 1, // 请求成功
  ERROR = 0, //  异常
  LOGIN_FAILURE = 800, // 登录失败
  NOT_AUTH = 801, // 没有权限
}

// 性别枚举
export enum SEX_CODE {
  UNKNOWN = 0, // 未知
  MAN = 1, // 男
  WOWAN = 2 // 女
}

// 平台渠道 1:微信 2:抖音 3:APP
export enum PLATFORM_CHANNEL {
  WEAPP = 1,
  TT = 2,
  APP = 3,
}

// 商品状态
export enum GOODS_STATUS {
  UP_SHELF = 10, // 上架
  OFF_SHELF = 20, // 下架
  END = 30, // 已结束
}

// 分销活动状态
export enum MARKET_ACTIVITY_STATUS {
  UP_SHELF = 10, // 上架
  OFF_SHELF = 20, // 下架
  END = 30, // 已结束
}

// 活动审核状态
export enum APPLY_STATUS {
  APPLY_WAIT_PASS = 0, // 等待提交审核
  APPLY_NOT_PASS = 1, // 未审核或审核失败
  APPLY_PASS = 2, // 审核通过
}

// 文件上传类型id
export enum UPLOAD_IMG_TYPE {
  IMAGE = 'WU_FILE_0', // 图片类型
}

// 平台渠道 key
export enum PLATFORM_CHANNEL_KEY {
  WEAPP = 'wx', // 微信
  TT = 'dy', // 抖音
}
// 平台渠道 key
export enum PLATFORM_CHANNEL_NAME {
  wx = '微信', // 微信
  dy = '抖音', // 抖音
}

// 规格类型
export enum SPEC_TYPE {
  ODD = 1, // 1:单规格
  EVEN = 2, // 2:多规格
}

// 订单提现状态
export enum ORDER_SETTLE {
  SETTLE_CAN_NOT_APPLY = 0, // 不能提现
  SETTLE_WAIT_APPLY = 1, // 等待提现
  SETTLE_ALREADY_APPLY = 2, // 已申请提现
  SETTLE_WAIT_APPLY_PASS = 3, // 等待审核
  SETTLE_APPLY_PASS = 4, // 审核通过
  SETTLE_PAY = 5, // 已打款
}

// 订单明细状态
export enum ORDER_STATUS {
  ALL = 'all', // 全部
  PAYMENT = 'payment', // 待付款
  DELIVERY = 'delivery', // 待核销
  COMPLETE = 'complete', // 已完成
  CANCEL = 'cancel', // 已取消
  REFUND = 'refund', // 已打款
}

// 1:活动详情页海报 2:首页推荐位海报 3:列表页海报 4: 分享图 5: 首屏广告位海报
export enum IMG_TYPE {
  DETAIL = '1', // 1:活动详情页海报
  DATE_BANNER = '2', // 2:首页推荐位海报
  LIST = '3', // 3:列表页海报
  SHARE = '4', // 4: 分享图
  BANNER = '5', // 5: 首屏广告位海报
  CONTENT = '6', // 活动详情长图
  APPLY = '7', // 审核文件
  COMMUNITY = '8' // 社群二维码
}

// 图片类型字段名
export enum IMAGE_TYPE_KEY_ENUM {
  SHARE = 'share_images[]',
  DETAIL = 'images[]',
  CONTENT = 'content_images[]',
  APPLY = 'apply_images[]',
  COMMUNITY = 'community_images[]'
}

// 订单核销状态
export enum RECEIPT_STATUS {
  PENDING = 10, // 待核销
  SUCCESS = 20, // 已核销
}

// 订单支付状态
export enum ORDER_PAY_STATUS {
  PENDING = 10, // 未支付
  SUCCESS = 20, // 已支付
}

// 订单详情状态
// (10进行中 20取消 21待取消 30已完成)
export enum ORDER_DETAIL_STATUS {
  RUN = 10, // 进行中
  CANCEL = 20, // 取消
  WAIT_CANCEL = 21, // 待取消
  FINISH = 30, // 已完成
}

// 地区
export enum AreaType {
  CHINA = 1,
  OVERSEAS = 2
}
