import { shopDetailApi } from '@/assets/scripts/apis/shop-api'
import { STATUS_CODE } from '@/assets/scripts/utils/enum'

export default {
  namespaced: true,
  state: {
    shopInfo: {}
  },
  mutations: {
    setShopInfo (state: {shopInfo: unknown}, data: unknown) {
      state.shopInfo = data
    }
  },
  actions: {
    // 获取主办方详情
    getShopInfo ({ commit }: { commit:any }) {
      shopDetailApi().then((res) => {
        if (res.code === STATUS_CODE.SUCCESS) {
          if (res.data) {
            commit('setShopInfo', res.data || {})
          }
        }
      })
    }
  }
}
