import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "layout-base px-container px-layout"
};
const _hoisted_2 = {
  class: "layout-bg"
};
const _hoisted_3 = {
  class: "layout-main-body hidden-scrollbar"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SideMenu = _resolveComponent("SideMenu");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_SideMenu), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "bg")]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default")])]);
}