import { get, post } from '../utils/request'

// 获取首页信息接口
export function getHomeInfoApi (data:any) {
  return get('/index/index', data)
}

// 退出登录
export function logoutApi () {
  return get('/passport/logout')
}

/**
 * 获取活动列表
 * @param data 参数
 * @param data.goodsStatus 10 上架 20 下架 30 结束
 */
export function getActivityListApi (data: { goodsStatus: number, page: number, listRows?: number, search?: string, goods_id?: string }) {
  return get('/goods/index', {
    goods_status: data.goodsStatus,
    page: data.page,
    listRows: data.listRows,
    search: data.search,
    goods_id: data.goods_id
  })
}

// 获取艺人列表
export function getAritstListApi (data: any) {
  return get('/actors/list', data)
}
// 获取艺人分类列表
export function getCategoryListApi () {
  return get('/actors/categoryList')
}
// 获取国家及地区列表
export function getLocationListApi () {
  return get('/actors/locationList')
}

// 申请预约艺人接口
export function applyArtistApi (data: string) {
  return post('/dsp/record', {
    type: 3,
    data
  }, { isForm: true })
}

// 申请推广接口
export function applySoundvibeApi (data: string) {
  return post('/dsp/record', {
    type: 1,
    data
  }, { isForm: true })
}
