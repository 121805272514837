
interface IAddressInfo {
  address: string,
  region: {
    province: string,
    city: string,
    region: string
  },
  province_id: number,
  city_id: number,
  region_id: number,
  longitude: string, // 经度
  latitude: string // 纬度
}

interface IShop {
  brand_image_id: number,
  shop_name: string,
  phone: string,
  logo: {
    file_path: string
  }
}

interface IGoodsDetail {
  goods_name: string,
  extra: {
    start_time_timestamp: number,
    end_time_timestamp: number
  },
  shop: {
    brand_image_id: number,
    shop_name: string,
    phone: string,
    logo: {
      file_path: string
    },
    is_table: number
  },
  selling_point: string
}

const initGoodsData = () => {
  return {
    goods_id: 0,
    goods_name: '',
    extra: {
      open_id_card: 0,
      open_force_real_name: 0,
      force_real_name_num: 1,
      start_time: '',
      end_time: '',
      share_title: '', // 分享标题
      start_time_timestamp: 0,
      end_time_timestamp: 0,
      close_sale_time: '',
      open_sale_time: '',
      open_refund: 1
    },
    image: [],
    spec_many: {
      wx: {
        spec_attr: [],
        spec_list: []
      },
      dy: {
        spec_attr: [],
        spec_list: []
      }
    },
    shop_id: 0,
    selling_point: '', // 活动简介
    category_id: 0, // 活动类型id
    open_sale_time: '',
    'share_images[]': { // 分享图
      file_path: ''
    },
    'images[]': { // 活动详情页海报图
      file_path: ''
    },
    'content_images[]': { // 活动详情长图
      file_path: ''
    },
    'apply_images[]': { // 审核问文件
      file_path: ''
    }
  }
}

const initAddressData = () => {
  return {
    address: '',
    address_type: '1', // 地址类型. 1.默认地址， 自定义地址
    region: {
      province: '',
      city: '',
      region: ''
    },
    area_type: 1,
    country: '',
    global_city: '',
    global_street: '',
    province_id: 0,
    city_id: 0,
    region_id: 0,
    longitude: '', // 经度
    latitude: '', // 纬度
    coordinate: '0,0' // 纬度,经度
  }
}

const initShopData = () => {
  return {
    brand_image_id: 0,
    shop_name: '',
    phone: '',
    logo: {
      file_path: ''
    },
    is_table: 0
  }
}

const initPreviewData = () => {
  return {
    address: {
      ...initAddressData()
    },
    shop: {
      ...initShopData()
    },
    ...initGoodsData(),
    step01: null,
    step02: null,
    step03: null,
    step04: null
  }
}

export default {
  namespaced: true,
  state: {
    loadGoodsStatus: false, // 是否加载商品数据
    loadShopStatus: false, // 是否加载主办方数据
    // 新增的商品信息
    addGoodsDetail: initGoodsData(),
    // 编辑的商品信息
    goodsDetail: initGoodsData(),
    // 地址信息
    addressInfo: initAddressData(),
    // 预览数据
    previewDetail: initPreviewData(),
    // 主办方信息
    shop: {
      ...initShopData()
    }
  },
  mutations: {
    setAddressInfo (state: { addressInfo: IAddressInfo }, data: IAddressInfo) {
      state.addressInfo = data
    },
    // 设置商品数据获取状态
    setLoadGoodsStatus (state: { loadGoodsStatus: boolean }, data: boolean) {
      state.loadGoodsStatus = data
    },
    // 设置活动信息
    setGoodsDetail (state: { goodsDetail: IGoodsDetail }, data: IGoodsDetail) {
      state.goodsDetail = data
    },
    // 设置活动信息
    setAddGoodsDetail (state: { addGoodsDetail: IGoodsDetail }, data: IGoodsDetail) {
      state.addGoodsDetail = data
    },
    setShop (state: { shop: IShop }, data: IShop) {
      state.shop = data
    },
    // 设置主办方数据获取状态
    setLoadShopStatus (state: { loadShopStatus: boolean }, data: boolean) {
      state.loadShopStatus = data
    },
    // 设置预览数据
    setPreviewData (state: { previewDetail: any }, data:any) {
      state.previewDetail = data
    },
    // 重置商品数据
    resetGoodsStore (state: any) {
      // 新增的商品信息
      state.addGoodsDetail = initGoodsData()
      // 编辑的商品信息
      state.goodsDetail = initGoodsData()
      // 地址信息
      state.addressInfo = initAddressData()
      // 预览数据
      state.previewDetail = initPreviewData()
    }
  },
  actions: {
  }
}
