import _ElDrawer2 from "element-plus/lib/theme-chalk/el-drawer.css";
import "element-plus/lib/theme-chalk/base.css";
import _ElDrawer from "element-plus/lib/el-drawer";
import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, reactive, ref } from 'vue';
import LayoutBase from '@/components/layout/base/index.vue';
import { getHomeInfoApi } from '@/assets/scripts/apis/home-api';
import { unitToString } from '@/assets/scripts/utils/format';
import { STATUS_CODE } from '@/assets/scripts/utils/enum';
import { EffectCards, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Artist, Soundvibe, Creative, ActivityManager } from '@/assets/scripts/utils/async-component';
import 'swiper/scss';
import 'swiper/scss/effect-cards';
import 'swiper/scss/effect-fade';
import 'swiper/scss/autoplay';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'HomePage',
  components: {
    LayoutBase,
    ElDrawer: _ElDrawer,
    Artist: defineAsyncComponent(Artist),
    Soundvibe: defineAsyncComponent(Soundvibe),
    Creative: defineAsyncComponent(Creative),
    ActivityManager: defineAsyncComponent(ActivityManager),
    Swiper,
    SwiperSlide
  },
  setup() {
    const router = useRouter();
    const selectPlatform = ref('0');
    const platformOptions = reactive([{
      type: '0',
      name: '全渠道'
    }, {
      type: '1',
      name: '微信渠道'
    }, {
      type: '2',
      name: '抖音渠道'
    }]);
    const dashBoardInfo = ref({});
    const artistDrawer = ref(false);
    const soundvibeDrawer = ref(false);
    const creativeDrawer = ref(false);
    const activityDrawer = ref(false);
    // 获取首页信息
    const getHomeInfo = () => {
      const params = {
        app: selectPlatform.value && selectPlatform.value !== '0' ? selectPlatform.value : undefined
      };
      getHomeInfoApi(params).then(res => {
        if (res.code === STATUS_CODE.SUCCESS) {
          dashBoardInfo.value = res.data.card;
        }
      });
    };
    // 参数改变
    const onChangePlatform = () => {
      getHomeInfo();
    };
    // 点击查看数字化赋能
    const clickDigital = () => {
      router.push({
        name: 'Service'
      });
    };
    // 点击查看艺术家
    const clickArtist = () => {
      artistDrawer.value = true;
    };
    // 点击查看推广
    const clickSoundvibe = () => {
      soundvibeDrawer.value = true;
    };
    // 点击查看创意服务
    const clickCreative = () => {
      creativeDrawer.value = true;
    };
    // 点击管理活动展开
    const clickActivityExpand = () => {
      activityDrawer.value = true;
    };
    const clickBannerItem = (url, type) => {
      if (type === 'router') {
        router.push({
          name: url
        });
      } else {
        window.location.href = url;
      }
    };
    const onSoundvibeSuccessBack = () => {
      soundvibeDrawer.value = false;
    };
    const onCreativeBack = () => {
      creativeDrawer.value = false;
    };
    const onActivityBack = () => {
      activityDrawer.value = false;
    };
    getHomeInfo();
    return {
      selectPlatform,
      platformOptions,
      dashBoardInfo,
      artistDrawer,
      soundvibeDrawer,
      creativeDrawer,
      activityDrawer,
      unitToString,
      clickDigital,
      clickArtist,
      clickSoundvibe,
      clickCreative,
      clickActivityExpand,
      clickBannerItem,
      onChangePlatform,
      onSoundvibeSuccessBack,
      onCreativeBack,
      onActivityBack,
      modules: [EffectCards, Autoplay]
    };
  }
});