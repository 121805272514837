import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "mobile flex flex-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_ConfigProvider = _resolveComponent("ConfigProvider");
  const _component_el_config_provider = _resolveComponent("el-config-provider");
  return _openBlock(), _createBlock(_component_el_config_provider, {
    locale: _ctx.locale
  }, {
    default: _withCtx(() => [_createVNode(_component_ConfigProvider, {
      locale: _ctx.antLocale
    }, {
      default: _withCtx(() => [_ctx.isPc ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_1, " 暂不支持移动设备，请使用PC电脑登录 "))]),
      _: 1
    }, 8, ["locale"])]),
    _: 1
  }, 8, ["locale"]);
}