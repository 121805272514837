import axios from 'axios'
import qs from 'qs'
import { API_BASE_URL } from './config'
import { TOKEN_KEY } from './constant'
import { STATUS_CODE } from './enum'
import { goToLogin } from './link'

const timeout = 10 * 1000 // 单位秒
const AUTH_TOKEN = localStorage.getItem(TOKEN_KEY) || ''
const defaultHeaders = {
  Authorization: AUTH_TOKEN,
  'Access-Token': AUTH_TOKEN
}

const formHeaders = { 'Content-Type': 'application/x-www-form-urlencoded' }
const fileHeaders = { 'Content-Type': 'multipart/form-data' }

const instance = axios.create({
  baseURL: API_BASE_URL,
  timeout: timeout,
  withCredentials: true, // 是否允许带cookie这些
  responseType: 'json',
  headers: defaultHeaders
})

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    const resp = response.data
    const code = Number(resp.code)
    if (STATUS_CODE.ERROR === code) {
      // 请求异常
    } else if (STATUS_CODE.LOGIN_FAILURE === code) {
      // 登录失败
      process.env.NODE_ENV === 'production' && goToLogin()
    } else if (STATUS_CODE.NOT_AUTH === code) {
      // 没有权限
      // Toast.warning(resp.msg || '没有权限访问');
    }
    return response
  }
)

export function get (url: string, params?: any, options?: any) {
  return instance.get(url, {
    params: params,
    ...options
  }).then(res => {
    return res.data
  })
}

export function post (url: string, data?: any, options?: any) {
  const { isForm, isFile, ...opt } = options || {}
  if (isForm) {
    opt.headers = {
      ...opt.headers,
      ...defaultHeaders,
      ...formHeaders
    }
    data = qs.stringify(data)
  } else if (isFile) {
    opt.headers = {
      ...opt.headers,
      ...defaultHeaders,
      ...fileHeaders
    }
  }

  return instance.post(url, data, opt)
    .then((res) => {
      return res.data
    })
}
