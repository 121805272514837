import { get, post } from '../utils/request'
/**
 * 主办方详情
 */
export const shopDetailApi = () => {
  return get('/shop/detail')
}

// 编辑主办方信息
export const shopEditApi = (data:unknown) => {
  return post('/shop/edit', data, { isForm: true })
}

// 编辑密码
export const editPwdApi = (data: unknown) => {
  return post('/store.user/editPwd', data, { isForm: true })
}
