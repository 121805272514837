import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/home/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/goods',
    name: 'Goods',
    component: () => import(/* webpackChunkName: "views.goods" */ '../views/goods/index.vue')
  },
  {
    path: '/goods/detail/:id?',
    name: 'GoodsDetail',
    meta: {
      slidMenu: 'Goods' // 非主菜单的路由，若要显示侧边栏菜单，则需要配置对应的主菜单路由名称
    },
    component: () => import(/* webpackChunkName: "views.goods.detail" */ '../views/goods/detail/index.vue')
  },
  {
    path: '/checker',
    name: 'Checker',
    component: () => import(/* webpackChunkName: "views.checker" */ '../views/checker/index.vue')
  },
  {
    path: '/market-share',
    name: 'Marketshare',
    component: () => import(/* webpackChunkName: "views.market-share" */ '../views/market-share/index.vue'),
    meta: {
      slidMenu: 'Service' // 非主菜单的路由，若要显示侧边栏菜单，则需要配置对应的主菜单路由名称
    }
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import(/* webpackChunkName: "views.order" */ '../views/order/index.vue')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import(/* webpackChunkName: "views.service" */ '../views/service/index.vue')
  },
  {
    path: '/handbook/brand',
    name: 'HandbookBrand',
    component: () => import(/* webpackChunkName: "views.ppt.brand" */ '../views/ppt/brand/index.vue')
  },
  {
    path: '/handbook/admin',
    name: 'HandbookAdmin',
    component: () => import(/* webpackChunkName: "views.ppt.brand" */ '../views/ppt/admin/index.vue')
  },
  {
    path: '/sponsor/index',
    name: 'SponsorIndex',
    component: () => import(/* webpackChunkName: "views.sponsor" */ '../views/sponsor/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
