import { API_ROOT_PATH } from './constant'

// 页面域名
export const PAGE_DOMAIN = process.env.VUE_APP_SAAS_DOMAIN_V1
// account 域名
export const ACCOUNT_DOMAIN = process.env.VUE_APP_ACCOUNT_DOMAIN
// 接口域名
export const API_DOMAIN = process.env.VUE_APP_SAAS_API_DOMAIN
// 接口基础路径
export const API_BASE_URL = `${API_DOMAIN}/${API_ROOT_PATH}`

// saas后台基础路径
export const SAAS_PAGE_ROOT_PATH = `${PAGE_DOMAIN}/saas`

// 首页路径
export const HOME_URL = '/'
