import "core-js/modules/es.array.push.js";
import { SAAS_PAGE_ROOT_PATH } from '@/assets/scripts/utils/config';
import { useStore } from 'vuex';
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'sideMenu',
  setup() {
    const store = useStore();
    const activeName = computed(() => store.state.layout.sideMenuActiveName);
    const router = useRouter();
    const shopInfo = computed(() => store.state.shop.shopInfo);
    const changeMenu = function (name) {
      store.commit('layout/setSideMenuActiveName', name);
    };
    const clickLogo = function () {
      // window.location.href = '/saas/#/store'
      router.push({
        name: 'SponsorIndex'
      });
    };
    const clickMenu = function (item) {
      switch (item.type) {
        case 'link':
          window.location.href = item.url || '';
          break;
        case 'router':
          router.push({
            name: item.name
          }).then(error => {
            if (!error) {
              changeMenu(item.name || '');
            }
          });
          break;
      }
    };
    // 退出登陆
    const clickLogout = () => {
      store.dispatch('layout/logout');
    };
    return {
      activeName,
      menuList: [{
        className: 'ico-home-6-fill',
        type: 'router',
        name: 'Home'
      }, {
        className: 'ico-appstore-fill',
        type: 'router',
        url: `${SAAS_PAGE_ROOT_PATH}/#/abm`,
        name: 'Goods'
      }, {
        className: 'ico-huodong-fill',
        // type: 'link',
        // url: `${SAAS_PAGE_ROOT_PATH}/#/order`,
        type: 'router',
        name: 'Order'
      }, {
        className: 'ico-idcard-fill',
        // type: 'link',
        // url: `${SAAS_PAGE_ROOT_PATH}/#/checker`,
        type: 'router',
        name: 'Checker'
      }, {
        className: 'ico-shop-fill',
        // type: 'link',
        // url: `${SAAS_PAGE_ROOT_PATH}/#/service`,
        type: 'router',
        name: 'Service'
      }],
      shopInfo,
      changeMenu,
      clickLogo,
      clickMenu,
      clickLogout
    };
  }
});