// 艺术家
const Artist = () => import(/* webpackChunkName: "views.home.components.artist" */'@/views/home/components/artist/index.vue')
// 推广
const Soundvibe = () => import(/* webpackChunkName: "views.home.components.soundvibe" */ '@/views/home/components/soundvibe/index.vue')

const Creative = () => import(/* webpackChunkName: "views.home.components.creative" */ '@/views/home/components/creative/index.vue')

const ActivityManager = () => import(/* webpackChunkName: "views.home.components.activity" */ '@/views/home/components/activity/index.vue')

export {
  Artist,
  Soundvibe,
  Creative,
  ActivityManager
}
