import { defineComponent } from 'vue';
import SideMenu from '../side-menu/index.vue';
export default defineComponent({
  name: 'LayoutBase',
  components: {
    SideMenu
  },
  setup() {
    return {};
  }
});