import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-19bfc3d2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "layout-side-menu hidden-scrollbar flex-col"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "menu-list flex-1 flex-col"
};
const _hoisted_4 = {
  class: "flex-1 flex flex-center"
};
const _hoisted_5 = {
  class: "common-menu-list"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  class: "flex-shrink-0 logout-menu flex flex-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a, _b;
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "logo flex-shrink-0",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.clickLogo && _ctx.clickLogo(...args))
  }, [_createElementVNode("img", {
    src: ((_b = (_a = _ctx.shopInfo) === null || _a === void 0 ? void 0 : _a.logo) === null || _b === void 0 ? void 0 : _b.file_path) || require('@/assets/images/layout/logo.png'),
    alt: ""
  }, null, 8, _hoisted_2)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("ul", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (item, index) => {
    return _openBlock(), _createElementBlock("li", {
      class: _normalizeClass(["menu-list__item flex flex-center", {
        'active': item.name === _ctx.activeName
      }]),
      key: index,
      onClick: $event => _ctx.clickMenu(item)
    }, [_createElementVNode("a", {
      class: _normalizeClass(["menu-icon", item.className])
    }, null, 2)], 10, _hoisted_6);
  }), 128))])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("a", {
    class: "menu-icon ico-logout-fill",
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.clickLogout && _ctx.clickLogout(...args))
  })])])]);
}