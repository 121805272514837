import _ElMessageBox2 from "element-plus/lib/theme-chalk/el-message-box.css";
import "element-plus/lib/theme-chalk/base.css";
import _ElMessageBox from "element-plus/lib/el-message-box";
import "ant-design-vue/es/config-provider/style/css";
import _ConfigProvider from "ant-design-vue/es/config-provider";
import _ElConfigProvider2 from "element-plus/lib/theme-chalk/el-config-provider.css";
import "element-plus/lib/theme-chalk/base.css";
import _ElConfigProvider from "element-plus/lib/el-config-provider";
import { defineComponent, ref, watch } from 'vue';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
export default defineComponent({
  components: {
    ElConfigProvider: _ElConfigProvider,
    ConfigProvider: _ConfigProvider
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const getPcViewport = () => window.matchMedia('(min-width:768px)').matches;
    const isPc = ref(getPcViewport());
    // 获取主办方详情
    const getShopDetail = () => {
      store.dispatch('shop/getShopInfo');
    };
    // 获取本地存储的构建日期
    const getLocalBuildDate = () => {
      return window.localStorage.getItem('BUILD_DATE');
    };
    // 保存本地存储的构建日期
    const setLocalBuildDate = date => {
      window.localStorage.setItem('BUILD_DATE', date);
    };
    // 检测是否更新
    const checkUpdate = () => {
      // 构建版本号
      const curBuildDate = process.env.BUILD_DATE;
      const localBuildDate = getLocalBuildDate();
      setLocalBuildDate(curBuildDate);
      return !!localBuildDate && curBuildDate !== Number(localBuildDate);
    };
    const initPage = () => {
      if (checkUpdate()) {
        _ElMessageBox.alert('系统版本有更新，请重新刷新页面', '版本更新提示', {
          confirmButtonText: '确定',
          center: true,
          showClose: false,
          callback: () => {
            window.location.reload();
          }
        });
      }
      getShopDetail();
    };
    watch(() => route.name, value => {
      store.commit('layout/setSideMenuActiveName', route.meta.slidMenu || value);
    });
    if (isPc.value) {
      initPage();
    }
    window.addEventListener('resize', () => {
      isPc.value = getPcViewport();
    });
    return {
      locale: zhCn,
      antLocale: zhCN,
      isPc
    };
  }
});