import { createStore } from 'vuex'
import LayoutModule from './modules/layout'
import Goods from './modules/goods'
import Shop from './modules/shop'

export default createStore({
  modules: {
    layout: LayoutModule,
    goods: Goods,
    shop: Shop
  }
})
